@use "src/assets/scss/utils" as u;


.main {
  flex: 1;
  padding: 0 u.size('unit');

  position: relative;
  z-index: u.z-index('before-header');
  @include u.below('md') {
    grid-area: search;
    padding: 0;
    z-index: u.z-index('before-modal');
    width: 100%;
  }

}

.form {
  position: relative;;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: u.size('unit');
  border: 2px solid u.var-color('primary');
  background-color: #fff;

  @include u.above('md') {
    padding: 0 1px;
  }

  @include u.below('md') {
    border: none;
    background-color: u.var-color('grey-5');
  }
}

.button {

  border-radius: 0 u.size('unit') u.size('unit') 0;
  margin: -2px -2px -2px 0;

  @include u.below('md') {
    margin: 0;
    height: 36px;
    display: none;
  }
}

.field {
  flex: 1;
  position: relative;

  @include u.below('md') {
    padding: 0 0 0 36px;
  }

  &-helper {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    color: u.var-color('text-ltr');
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
    visibility: hidden;
    font-size: u.size('text-sm');
    padding: 0 u.size('unit-4x') 0 u.size('unit');

    @include u.below('md') {
      display: none;
    }
  }

  &-mobile-icon {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    color: u.color('grey-50');

    svg {
      @include u.square(u.size('unit-half-3x'))
    }
  }
}

.input {
  width: 100%;
  border-radius: u.size('unit') 0 0 u.size('unit') !important;
  height: 36px;
  margin: 0;
  box-shadow: none !important;
  border: none !important;
  font-size: u.size('text-sm');
  padding: 0 u.size('unit-4x') 0 u.size('unit');
  background-color: transparent !important;

  &:focus {
    border-color: none;
    box-shadow: none;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  @include u.below('md') {
    background-color: transparent !important;
    $placeholder: u.get-color-value('grey-60');

    &::placeholder {
      color: $placeholder;
    }

    &:-ms-input-placeholder {
      color: $placeholder;
    }

    &::-ms-input-placeholder {
      color: $placeholder;
    }
    padding: 0 u.size('unit');
  }
}

.reset {
  position: absolute;
  right: u.size('unit');
  top: 50%;
  padding: 0;
  transform: translateY(-50%);
  transition: color .25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  border: none;
  display: flex;

  &:global(.show) {
    opacity: 1;
    visibility: visible;
  }

  svg {
    @include u.square(u.size('unit-2x'));
  }

  @include u.mixins-hover {
    color: u.var-color('primary');
  }

  @include u.below('md') {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}


.select {
  background-color: u.var-color('grey-5');
  display: flex;
  max-width: 125px;
  height: 34px;
  padding: 0 u.size('unit');
  align-items: center;
  cursor: pointer;
  border-radius: u.size('unit');
  color: u.var-color('grey-60');
  transition: color .25s ease-in-out;


  @include u.below('md') {
    display: none;
  }

  @include u.mixins-hover {
    color: u.var-color('secondary')
  }

  &-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: u.size('text-sm');
    font-weight: 500;
    user-select: none;
  }

  &-icon {
    @include u.square(u.size('unit-2x'));
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 u.size('unit');

    svg {
      @include u.square(u.size('unit'));
    }
  }

  &-btn {
    border-radius: 100%;
    display: flex;
    align-items: center;
    padding: u.size('unit-half');
    background-color: #fff;
    color: u.var-color('primary');
    transition: background-color .25s ease-in-out;
    margin: 0 0 0 u.size('unit');
    border: none;

    svg {
      @include u.square(u.size('unit'));
    }

    @include u.mixins-hover {
      background-color: u.var-color('grey-15');
    }
  }

  &:global(.active) {
    background-color: u.var-color('primary');
    color: #fff;
    cursor: auto;
    margin: -2px 0 -2px -2px;
    height: u.size('unit-5x');
  }
}

.search-categories {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  font-size: u.size('text-sm');
  font-weight: 500;

  &-current {
    width: 100%;
    margin: 0 0 u.size('unit-3x');
    display: flex;
    justify-content: space-between;
  }

  &-item {
    cursor: pointer;
    display: flex;
    text-align: center;
    width: calc(50% - #{u.size('unit')});
    padding: u.size('unit-half-2x') u.size('unit');
    transition: color .25s ease-in-out, background-color .25s ease-in-out;
    background-color: transparent;
    border-radius: u.size('unit');
    align-items: center;

    &-label {

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icon {
      display: flex;
      margin: 0 u.size('unit') 0 0;
      min-width: u.size('unit-3x');
      color: u.var-color('secondary');

      svg {
        @include u.square(u.size('unit-3x'));

      }
    }

    @include u.mixins-hover {
      background-color: u.var-color('grey-5');
      color: u.var-color('secondary');
    }
  }
}

.suggestions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include u.mixins-box-shadow-md(6);
  background-color: #fff;
  border-radius: u.size('unit');
  overflow: hidden;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  margin: -#{u.size('unit')} 0 0;

  @include u.below('md') {
    display: flex;
    flex-direction: column;
    position: fixed;
    min-height: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    overflow-y: auto;
  }


  &:before {
    content: '';
    width: 100%;
    height: 52px;
    display: block;

    @include u.below('md') {
      height: 96px;
      min-height: 96px;
    }
  }

  &-list {
    width: 100%;
    padding: u.size('unit-2x') 0 0;
    display: flex;
    flex-direction: column;

    @include u.below('md') {
      display: block;
      background-color: #fff;
      flex: 1;
      min-height: 0;
      padding: u.size('unit') 0 0;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: u.size('unit-6x');
      }
    }
  }

  &-history {
    padding: 0 u.size('unit') u.size('unit-half');
    border-bottom: 1px solid u.var-color('grey-15');
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: u.size('text-xs');
    @include u.below('md') {
      padding: 0 u.size('unit') u.size('unit');
      font-size: u.size('text-sm');
    }

    &-clean {

      cursor: pointer;
      transition: color .25s ease-in-out;
      color: u.var-color('grey-50');

      @include u.mixins-hover {
        color: u.var-color('primary');
      }
    }
  }

  &-label {
    font-weight: 700;
    font-size: u.size('text-xs');

    @include u.below('md') {
      font-size: u.size('text-sm');
    }
  }

  &-item {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: u.size('unit-half') u.size('unit') u.size('unit-half') 0;
    //border-bottom: 1px solid u.var-color('grey-15');
    @include u.mixins-hover {
      background-color: u.var-color('grey-5');
      .suggestions-item-title {
        color: u.var-color('secondary');
      }
    }

    &:global(.focused) {
      background-color: u.var-color('grey-10');
    }

    &-image {
      width: 100%;
      position: relative;

      &:global(.avatar) {
        border-radius: 100%;
        overflow: hidden;
        border: 2px solid u.var-color('grey-50');
      }

      &:before {
        content: '';
        padding-bottom: 100%;
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    @include u.below('md') {
      padding: u.size('unit-half-2x') u.size('unit') u.size('unit-half-2x') 0;
      border-bottom: 1px solid u.var-color('grey-15');
    }

    &-meta {
      width: u.size('unit-4x');
      //min-width: u.size('unit-7x');
      display: flex;
      align-items: center;
      color: u.var-color('grey-50');
      padding: 0 u.size('unit');
      justify-content: center;

      svg {
        @include u.square(u.size('unit-half-2x'));
      }

      @include u.below('md') {
        width: u.size('unit-7x');

        svg {
          @include u.square(u.size('unit-half-3x'));
        }
      }
    }

    &-title {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 u.size('unit') 0 0;
      font-size: u.size('text-xs');
      color: u.var-color('text');

      @include u.below('md') {
        font-size: u.size('text-sm');
      }
    }


    &-icon {
      border: none;
      margin: 0 0 0 auto;
      color: u.var-color('grey-50');

      svg {
        @include u.square(u.size('unit-half-2x'));
        @include u.below('md') {
          @include u.square(u.size('unit-2x'));
        }
      }

      padding: 0;
      display: flex;
      align-items: center;
      transition: color .25s ease-in-out;
    }

    &-button {
      @include u.mixins-hover {
        color: u.var-color('primary');
      }
    }
  }

  &-close {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    color: u.var-color('text');
    right: u.size('unit');
    width: u.size('unit-10x');
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: 8px;
    font-size: u.size('text-sm');
    z-index: 2;
  }
}

.title {
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 2;
  font-size: u.size('text-lg');
  font-weight: 700;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 u.size('unit');
  opacity: 0;
  visibility: hidden;
}

.main {
  &:global(.open-suggestions) {

    @include u.below('md') {
      &:before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        background-color: #fff;
        width: 100%;
        height: 96px;
        z-index: 2;
      }
      .title {
        opacity: 1;
        visibility: visible;
      }
      .suggestions-close {
        opacity: 1;
        visibility: visible;
      }
    }

    .suggestions {
      opacity: 1;
      visibility: visible;


    }

    .reset:global(.show) {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}

.suggestions-sublist {
  margin: u.size('unit-half-2x') 0 0;

  > .suggestions-label {
    padding: 0 u.size('unit') u.size('unit-half');

    @include u.below('md') {
      border-bottom: 1px solid u.var-color('grey-15');
    }
  }

  @include u.below('md') {
    margin: u.size('unit-half-3x') 0 0;
  }
}

.modal-more {
  @include u.above('md') {
    width: u.size('xs');
  }
}
