@use "src/assets/scss/utils" as u;


.main {
  width: 100%;
  display: flex;
  align-items: center;
  @include u.below('md') {
    display: grid;
    grid-template: "menu logo user" 36px "search search search" 36px / 36px 1fr 36px;
    grid-gap: u.size('unit') u.size('unit');
    height: 100%;
  }
}

.media {
  width: 100%;
  display: flex;
  align-items: center;
}

.user-btn {
  -webkit-tap-highlight-color: transparent;
  transition: none;
  @include u.square(36px);
  line-height: 1;
  padding: 0 u.size('unit');
  display: none;
  align-items: center;
  color: u.var-color('text');
  border-radius: 0;
  background-color: transparent;

  svg {
    @include u.square(u.size('unit-half-3x'));
  }

  &:focus {
    box-shadow: none;
    color: u.color('text') !important;
  }

  &-media {
    order: 3;
  }

  @include u.below('md') {
    display: flex;
    grid-area: user;
  }
}

.logo {
  grid-area: logo;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
  }
}
