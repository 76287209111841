@use "src/assets/scss/utils" as u;

.main {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 u.size('unit-2x') 0 0;

  @include u.below('md') {
    margin: 0;
    grid-area: menu;
  }
}

.button {
  &-icon {
    display: flex;
    margin: 0 u.size('unit') 0 0;

    svg {
      @include u.square(18px);
    }
  }

  @include u.below('md') {
    transition: none;
    @include u.square(36px);
    line-height: 1;
    padding: 0 u.size('unit');
    display: flex;
    align-items: center;
    color: u.var-color('text');
    border-radius: 0;
    background-color: transparent;
    margin: 0;
    &:focus {
      box-shadow: none;
      color: u.color('text') !important;
    }
    -webkit-tap-highlight-color: transparent;
    &-text {
      display: none;
    }

    &-icon {
      margin: 0;

      svg {
        @include u.square(u.size('unit-half-3x'));
      }
    }
  }
}


.list {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: u.z-index('before-header');
  list-style: none;
  @include u.mixins-box-shadow-md(6);
  background-color: u.var-color('grey-5');
  border-radius: u.size('unit');
  overflow: hidden;

  @include u.below('md') {
    position: fixed;
    width: 100%;
    transition: none;
    z-index: u.z-index('modal');
    top: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    overflow-y: auto;
    padding: u.size('unit-7x') 0 0;
  }

  &:global(.categories-button-enter) {
    opacity: 0;
    visibility: hidden;

    @include u.above('md') {
      transform: translate(0, u.size('unit-4x'));
    }
  }

  &:global(.categories-button-enter-active) {
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .15s ease-in-out;
    opacity: 1;
    visibility: visible;

    @include u.above('md') {
      transform: translate(0, 0);
    }
  }

  &:global(.categories-button-enter-done) {
    opacity: 1;
    visibility: visible;

    @include u.above('md') {
      transform: translate(0, 0);
    }
  }

  &:global(.categories-button-exit-active) {
    opacity: 0;
    visibility: hidden;

    @include u.above('md') {
      transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .15s ease-in-out;
      transform: translate(0, u.size('unit-4x'));
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    display: flex;

    overflow: hidden;

    &-media {
      display: flex;
      width: 100%;
      height: 100%;

      @include u.below('md') {
        height: auto;
        flex-direction: column;
        font-size: u.size('text-sm');
        position: relative;
      }
    }

    @include u.below('md') {
      height: auto;
      overflow: visible;
      //overflow-y: auto;
      padding: 0;
      //flex: 1;
      //height: auto;
    }
  }
}

.navigation {
  overflow: hidden;
  height: 100%;
  width: 300px;
  padding: u.size('unit-2x') 0;

  &-content {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &-link {
    width: 100%;
    display: flex;
    border-radius: u.size('unit') 0 0 u.size('unit');
    background-color: transparent;
    transition: none;
    overflow: hidden;
    padding: u.size('unit-half-2x') u.size('unit');
    align-items: center;
    text-decoration: none;
    outline: none;
    color: u.var-color('text');

    &-text {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: u.size('text-sm');
    }

    &-icon {
      margin: 0 u.size('unit') 0 0;
      min-width: u.size('unit-3x');
      color: u.var-color('text-ltr');

      svg {
        @include u.square(u.size('unit-3x'));

      }
    }
  }


  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    padding: 0 0 0 u.size('unit');
    margin: 0;


    &-item {
      width: 100%;

      //&:not(:first-child) {
      //		margin: u.size('unit') 0 0;
      //}

      @include u.mixins-hover {

        > .navigation-link {
          background-color: #fff;
          color: u.var-color('secondary');

          .navigation-link-icon {
            color: u.var-color('secondary');
          }
        }
      }

      &:global(.hover) {
        > .navigation-link {
          background-color: #fff;
          color: u.var-color('secondary');

          .navigation-link-icon {
            color: u.var-color('secondary');
          }
        }
      }
    }
  }

  &-additional {
    margin: u.size('unit-2x') 0 0;
    padding: u.size('unit-2x') 0 0 u.size('unit');
    border-top: 1px solid u.var-color('grey-15');
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;

    .navigation-link {
      border-radius: u.size('unit');
    }

    .navigation-list-item {
      @include u.above('md') {
        padding: 0 u.size('unit-2x') 0 0;
      }
    }
  }

}

.categories {
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: u.size('unit-2x') 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &-banner {
    width: 100%;
    padding: 0 u.size('unit');
    margin: 0 0 u.size('unit-2x');

    @include u.below('md') {
      margin: u.size('unit') 0;
      padding: 0;
    }

    &-img {
      width: 100%;
      max-width: none;
      height: auto;
    }
  }

  &-scroll {
    display: flex;
    flex-direction: column;
  }

  &-content {
    width: 100%;
    position: relative;
    padding: u.size('unit') u.size('unit-2x') u.size('unit') u.size('unit-2x');
    display: block;
    font-size: u.size('text-sm');
    column-count: 3;
    column-gap: u.size('unit-2x');
    flex: 1;

    a {
      @include u.mixins-hover {
        color: u.var-color('secondary');
      }
      transition: .25s ease-in-out;
    }

    &-list {
      display: inline-block;
      width: 100%;
      list-style: none;
      padding: 0;

      margin: 0 0 u.size('unit-3x');

      &-title {
        font-weight: 700;
        margin: 0 0 u.size('unit');
        width: 100%;
        color: u.var-color('secondary');

        @include u.mixins-hover {
          color: u.var-color('secondary-dk') !important;
        }
      }


    }

    &-sublist {
      width: 100%;
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;

      &-item {
        width: 100%;
        margin: u.size('unit') 0 0;
      }

      &-link {
        color: u.var-color('grey-50');

        &-text {
          width: 100%;
        }
      }
    }
  }
}

.header {
  display: none;
  align-items: center;
  height: u.size('unit-7x');
  min-height: u.size('unit-7x');
  width: 100%;
  color: u.var-color('text');
  position: relative;
  border-bottom: 1px solid u.var-color('grey-15');

  &-title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    padding: 0 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    font-size: u.size('text-md');
  }

  @include u.below('md') {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: u.var-color('grey-5');

  }

  &-back {
    display: flex;
    justify-content: center;
    align-items: center;
    @include u.square(36px);

    svg {
      @include u.square(u.size('unit-half-3x'));
    }
  }

  &-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 auto;
    @include u.square(36px);

    svg {
      @include u.square(u.size('unit-half-3x'));
    }
  }
}

.nav-btn {
  &-label {
    flex: 1;
    min-width: 0;
  }

  min-height: u.size('unit-6x');
  width: 100%;
  background-color: transparent;
  user-select: none;
  border-color: u.var-color('grey-15');
  border-width: 0 0 1px 0;
  color: u.var-color('text');
  padding: 0 (u.size('unit-3x') + u.size('unit-half')) 0 u.size('unit');
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  border-style: solid;

  &:last-child {
    margin: 0 0 u.size('unit-10x');
  }

  &-check {
    position: absolute;
    top: 50%;
    right: u.size('unit');
    transform: translate(0, -50%);
    @include u.square(u.size('unit-2x'));
    margin: 0 0 0 u.size('unit');
    color: u.var-color('primary');

    svg {
      @include u.square(u.size('unit-2x'));
    }
  }

  &-icon {
    //@include u.square(u.size('unit-6x'));
    color: u.var-color('secondary');
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 u.size('unit') 0 0;

    svg {
      @include u.square(u.size('unit-half-3x'));
    }
  }
}

.nav-list {
  padding: 0 0 0 u.size('unit-half-2x');

  .nav-btn {
    padding: 0 (u.size('unit-3x') + u.size('unit-half')) 0 0;
  }
}

.mobile-nav {
  position: relative;
}

.mobile-nav-external {
  z-index: u.z-index('before-modal');
  width: 100%;
  position: fixed;
  bottom: 0;
  border-radius: u.size('unit') u.size('unit') 0;
  overflow: hidden;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: u.size('unit-8x');
  background-color: #fff;
  @include u.mixins-box-shadow-md(3);
  padding: 0 u.size('unit');

  &-btn {
    font-size: u.size('text-sm');
    width: 100%;
  }
}

.categories-btn {
  background: linear-gradient(to right, u.var-color('primary-dk') 0%, u.var-color('primary-lt') 100%);
  color: #fff;
  border: none;

  svg {
    color: #fff;
  }
}

.purchases-btn {
  background: linear-gradient(to right, u.var-color('turquoise-dk') 0%, u.var-color('turquoise-lt') 100%);
  color: #fff;
  border: none;

  svg {
    color: #fff;
  }
}
